body {
  font-family: -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  width: 100vw;
  max-height: 56.25vw;
  max-width: 177.78vh;
  height: 100%;
  background-color: black;
}

#language > .ant-popover-inner-content {
  width: 8vw;
  display: flex;
  flex-direction: column;
  padding: 1vh 0 !important;
}

#music > .ant-popover-inner-content {
  width: 2.5vw;
  display: flex;
  justify-content: center;
  padding: 2vh 0 !important;
}

.ant-popover-inner {
  border-radius: var(--border-radius) !important;
}

.ant-popover-arrow {
  display: none !important;
}


@media only screen and (max-width: 850px) {
  .ant-popover-inner {
    border-radius: 6px !important;
  }
  .ant-popover.ant-popover-placement-bottom {
    top: 20px !important;
  }
}

.ant-image-preview-mask {
  width: 100vw;
  max-height: 56.25vw;
  max-width: 177.78vh;
  padding: 0px;
  margin: auto;
  border: 0px;
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.ant-image-preview-wrap {
  width: 100vw;
  max-height: 56.25vw;
  max-width: 177.78vh;
  padding: 0px;
  margin: auto;
  border: 0px;
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.ant-modal-mask {
  width: 100vw;
  max-height: 56.25vw;
  max-width: 177.78vh;
  padding: 0px;
  margin: auto;
  border: 0px;
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

/* .ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-popup.ant-dropdown-menu.ant-dropdown-menu-light.ant-dropdown-menu-submenu-placement-rightTop {
  left: 83% !important;
}

.ant-dropdown-menu-submenu-popup
  ul {
  border-radius: 6px;
}

ul.ant-dropdown-menu.ant-dropdown-menu-light.ant-dropdown-menu-root.ant-dropdown-menu-vertical {
  border-radius: 6px;
}
.ant-dropdown.ant-dropdown-placement-bottomCenter {
  width: 3vw !important;
  min-width: 3vw !important;
  max-width: 3vw !important;
}
.ant-dropdown-menu-submenu-title {
  padding: 5% 16% !important;
}
span.ant-dropdown-menu-submenu-expand-icon {
  display: none;
} */

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 0.5vw;
  background: #e5e5e5;
  border-radius: var(
    --border-radius
  );
  display: flex;
}
::-webkit-scrollbar-thumb {
  border-radius: var(
    --border-radius
  );
  background-color: #bcbec0;
  box-shadow: 0 0
    1px
    rgba(
      255,
      255,
      255,
      0.5
    );
}

/* @media screen and (min-width: 320px) and (max-width: 767px) and (orientation: portrait) {
  #game-container
    > div {
    transform: rotate(
      90deg
    );
    width: calc(
      100vh
    );
    height: calc(
      100vw
    );
    position: absolute;
    right: 0px;
  }
} */
